import { MenuEntry } from '@pancakeswap-libs/uikit'

const config:(t:any)=> MenuEntry[] =(t:any)=> [
  {
    label: t(2026,'Bridge'),
    icon: 'ToyIcon2',
    href: '/',
  },
  {
    label: t(61,'Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t(7,'Exchange'),
        href: 'https://dex.benswap.cash/',
      },
      {
        label: t(8,'Liquidity'),
        href: 'https://dex.benswap.cash/#/pool'
      },
      {
        label: t(1146,'Aggregator'),
        href: 'https://dex.benswap.cash/#/dexaggregator',
      },
      {
        label: t(1164,'Aggregator Pro'),
        href: 'https://dex.benswap.cash/#/aggregatorpro'
      }
    ],
  },
  {
    label: t(2069,'All Features (Under Maintenance)'),
    icon: 'HomeIcon',
    href: 'https://full.benswap.cash'
  },
  {
    label: t(56,'More'),
    icon: 'MoreIcon',
    items: [
      {
        label:t(57,'Github'),
        href: "https://github.com/BenTokenFinance",
      },
      {
        label: t(58,'Docs'),
        href: `https://docs.benswap.cash/${t(1330,'')}`,
      }
    ],
  },
]

export default config
